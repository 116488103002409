import { AxiosError } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'

import { config } from '../configs'
import { NODE_ENV } from './Type'
import { DEFAULT_FORMAT, DEFAULT_TZ } from './Constant'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)

class Utility {
  public static getNodeEnv() {
    const defaultEnv = NODE_ENV.DEVELOPMENT
    const currentEnv =
      process.env.NUXT_ENV_RIDA_DRIVER_APP_ADMIN_DASHBOARD_NODE_ENV

    return _.values(NODE_ENV).includes(currentEnv as NODE_ENV)
      ? (currentEnv as NODE_ENV)
      : defaultEnv
  }

  public static getConfig() {
    const env = this.getNodeEnv()
    return config[env]
  }

  public static isProduction() {
    return this.getNodeEnv() === NODE_ENV.PRODUCTION
  }

  public static isStaging() {
    return this.getNodeEnv() === NODE_ENV.STAGING
  }

  public static notEmpty<TValue>(
    value: TValue | null | undefined,
  ): value is TValue {
    return (
      value !== null &&
      value !== undefined &&
      value !== ('' as unknown as TValue)
    )
  }

  public static isAxiosError<T>(error: Error): error is AxiosError<T> {
    return (error as AxiosError).isAxiosError !== undefined
  }

  public static trimSide<T>(str: T) {
    if (typeof str === 'string') {
      return _.chain(str).trimStart().trimEnd().value()
    }

    return str
  }

  public static isNumber<T>(value: T) {
    if (_.isString(value)) {
      return /^-?\d+\.?\d*$/.test(value)
    }

    return _.isFinite(value)
  }

  public static isEmail(s: string) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      s,
    )
  }

  public static getNearestTimeByStep(
    timeChosen = dayjs(),
    step = 30,
    isForward = true,
  ) {
    const pivotTime = dayjs(timeChosen)
    if (isForward) {
      return pivotTime
        .minute(Math.ceil(pivotTime.minute() / step) * step)
        .startOf('minute')
    }

    return pivotTime
      .minute(Math.floor(pivotTime.minute() / step) * step)
      .startOf('minute')
  }

  // see https://stackoverflow.com/questions/34993292/how-to-save-xlsx-data-to-file-as-a-blob
  public static stringToArrayBuffer(str: string) {
    const binary = atob(str)
    const buffer = new ArrayBuffer(binary.length)
    const view = new Uint8Array(buffer)
    _.each(str, (_s, index) => {
      view[index] = binary.charCodeAt(index) & 0xff
    })

    return buffer
  }

  public static generateDownloadFile(base64Content: string, fileTitle: string) {
    const url = window.URL.createObjectURL(
      new Blob([this.stringToArrayBuffer(base64Content)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileTitle)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  public static isStringAUrl(str?: string | null) {
    return str ? _.startsWith(str, 'https') : false
  }

  public static getHumanReadableDuration(diffSecond: number) {
    const minInSecond = 60
    const hourInSecond = 3600
    const dayInSecond = hourInSecond * 24
    const weekInSecond = dayInSecond * 7
    const monthInSecond = dayInSecond * 30

    const months = Math.floor(diffSecond / monthInSecond)
    diffSecond -= monthInSecond * months

    const weeks = Math.floor(diffSecond / weekInSecond)
    diffSecond -= weekInSecond * weeks

    const days = Math.floor(diffSecond / dayInSecond)
    diffSecond -= dayInSecond * days

    const hours = Math.floor(diffSecond / hourInSecond)
    diffSecond -= hourInSecond * hours

    const minutes = Math.floor(diffSecond / minInSecond)

    // for second, dont need to show

    let result = ''
    result += months ? months + 'mth ' : ''
    result += weeks ? weeks + 'w ' : ''
    result += days ? days + 'd ' : ''
    result += hours ? hours + 'hr ' : ''

    // in case diffSecond = 0
    if (result) {
      result += minutes ? minutes + 'min ' : ''
    } else {
      result += (minutes || 0) + 'min '
    }

    return _.trim(result)
  }

  public static getHumanReadableDistance(diffMetres: number) {
    const kmInMetres = 1000
    return `${_.round(diffMetres / kmInMetres, 2)}km`
  }

  public static parseWindowString(date: Dayjs, window: string) {
    const w = _.split(window, '-')
    const start = w[0]
    const end = w[1]

    const s = dayjs.tz(
      `${date.format(DEFAULT_FORMAT.DATE)} ${start}`,
      DEFAULT_FORMAT.DATE_TIME_4,
      DEFAULT_TZ,
    )
    const e = dayjs.tz(
      `${date.format(DEFAULT_FORMAT.DATE)} ${end}`,
      DEFAULT_FORMAT.DATE_TIME_4,
      DEFAULT_TZ,
    )

    return {
      start: s,
      end: e.isBefore(s) ? e.add(1, 'd') : e,
    }
  }
}

export { Utility as UtilityService }
