import { AxiosError } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import _ from 'lodash'
import { Component, Vue } from 'nuxt-property-decorator'

import {
  DEFAULT_FORMAT,
  DEFAULT_TZ,
  DRIVER_EQUIPMENT_TYPE,
  ITEM_CATEGORY_TYPE,
  JOB_STATUS_TYPE,
  ORDER_SERVICE_TYPE,
  REGION,
  STOP_TYPE,
  TASK_DELIVERY_STATUS_TYPE,
  UtilityService,
  V1RidaApiErrorResponse,
  VALUE_TYPE,
  VEHICLE_TYPE,
} from '../services'

@Component
export default class UtilityMixins extends Vue {
  public get $isMobile() {
    return this.$vuetify.breakpoint.xs
  }

  public get $isTablet() {
    return this.$vuetify.breakpoint.sm
  }

  public get $isTabletOrBelow() {
    return this.$isTablet || this.$isMobile
  }

  public $getDate(d: Dayjs | string) {
    return dayjs(d).tz(DEFAULT_TZ).format(DEFAULT_FORMAT.DATE_2)
  }

  public $getTime(d: Dayjs | string) {
    return dayjs(d).tz(DEFAULT_TZ).format(DEFAULT_FORMAT.TIME_ONLY)
  }

  public $getTime2(d: Dayjs | string) {
    return dayjs(d).tz(DEFAULT_TZ).format(DEFAULT_FORMAT.TIME_ONLY_2)
  }

  public $getDateTime(d: Dayjs | string) {
    return dayjs(d).tz(DEFAULT_TZ).format(DEFAULT_FORMAT.DATE_TIME)
  }

  public $getDateTimeFull(d: Dayjs | string) {
    return dayjs(d).tz(DEFAULT_TZ).format(DEFAULT_FORMAT.DATE_TIME_3)
  }

  public $getDateTimeMobileView(d: Dayjs | string) {
    return dayjs(d).tz(DEFAULT_TZ).format(DEFAULT_FORMAT.DATE_TIME_2)
  }

  public $getPrice(p?: string | number) {
    if (!p || !UtilityService.isNumber(p)) return '$0.00'

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    return formatter
      .format(parseFloat(p.toString()))
      .replace(/^(?!-).*\$/g, '$')
  }

  public $getPhone(user?: {
    phone_country_code?: string | null
    phone_number?: string | null
  }) {
    if (user?.phone_number && user.phone_country_code) {
      return `${user?.phone_country_code}${user?.phone_number}`
    }

    return ''
  }

  public $getName(
    user?: {
      first_name?: string | null
      last_name?: string | null
      preferred_name?: string | null
    },
    defaultValue = 'N.A',
  ) {
    let name = ''

    if (user?.first_name) {
      name += `${user.first_name} `
    }

    if (user?.last_name) {
      name += `${user.last_name} `
    }

    if (user?.preferred_name) {
      name += `(${user.preferred_name})`
    }

    return name || defaultValue
  }

  public $getAlphabetFromIndex(index: number) {
    return String.fromCharCode(index + 'A'.charCodeAt(0))
  }

  public $getBgColorTaskDeliveryStatus(status: number) {
    switch (status) {
      case TASK_DELIVERY_STATUS_TYPE.PENDING:
        return 'primary'
      case TASK_DELIVERY_STATUS_TYPE.STARTED:
      case TASK_DELIVERY_STATUS_TYPE.REACHED:
        return 'statusYellow'
      case TASK_DELIVERY_STATUS_TYPE.COMPLETED:
        return 'green'
      case TASK_DELIVERY_STATUS_TYPE.FAILED:
        return 'red'
      default:
        return 'grey'
    }
  }

  public $getBgColorRegion(region: string) {
    switch (region) {
      case REGION.CENTRAL:
        return this.$getColorList()[0].color
      case REGION.EAST:
        return this.$getColorList()[1].color
      case REGION.NORTH:
        return this.$getColorList()[2].color
      case REGION.NORTH_EAST:
        return this.$getColorList()[3].color
      case REGION.WEST:
        return this.$getColorList()[4].color
      case REGION.CBD:
        return this.$getColorList()[7].color
      case REGION.SOUTH:
        return this.$getColorList()[8].color
      default:
        return 'grey'
    }
  }

  public $getBgColorJobStatus(status: number) {
    switch (status) {
      case JOB_STATUS_TYPE.PENDING:
      case JOB_STATUS_TYPE.ACCEPTED:
      case JOB_STATUS_TYPE.LOOKING_FOR_DRIVER:
        return 'primary'
      case JOB_STATUS_TYPE.ONGOING:
        return 'statusYellow'
      case JOB_STATUS_TYPE.COMPLETED:
        return 'green'
      case JOB_STATUS_TYPE.FAILED:
        return 'red'
      default:
        return 'grey'
    }
  }

  public $getBgColorOrderServiceType(type?: number) {
    switch (type) {
      case ORDER_SERVICE_TYPE.SELF_MANAGED:
        return 'statusYellow'
      default:
        return 'grey'
    }
  }

  public $getStopType(type: number) {
    return type === STOP_TYPE.PICK_UP
      ? { icon: 'mdi-tray-arrow-up', color: 'red', text: 'Pick-up' }
      : {
          icon: 'mdi-tray-arrow-down',
          color: 'green darken-1',
          text: 'Drop-off',
        }
  }

  public $hasSelfManagedRole() {
    return this.$accessor.user?.hasSelfManagedRole
  }

  public $getDeliveryWindow(task: {
    delivery_window_start: string | null
    delivery_window_end: string | null
  }) {
    if (task.delivery_window_end && task.delivery_window_start) {
      const startW = dayjs(task.delivery_window_start)
      const endW = dayjs(task.delivery_window_end)
      const diffD = endW.diff(startW, 'd')

      return `${dayjs(task.delivery_window_start)
        .tz(DEFAULT_TZ)
        .format(DEFAULT_FORMAT.TIME_ONLY_2)} - ${dayjs(task.delivery_window_end)
        .tz(DEFAULT_TZ)
        .format(DEFAULT_FORMAT.TIME_ONLY_2)}${diffD ? ` (+${diffD})` : ''}`
    }

    return 'N.A'
  }

  public $getTaskStatus(
    task: {
      task_delivery_status_type_id: number
    },
    statusList: {
      id: number
      name: string
    }[],
    options?: { optimizedForMobile: boolean },
  ) {
    const status = _.find(
      statusList,
      t => t.id === task.task_delivery_status_type_id,
    )
    const text = status?.name || 'N.A'
    return {
      background: this.$getBgColorTaskDeliveryStatus(
        task.task_delivery_status_type_id,
      ),

      // this text on mobile is too long so need to reduce
      text: !options?.optimizedForMobile
        ? text
        : !this.$isMobile
        ? text
        : _.first(status?.name) || 'N.A',
    }
  }

  public $getJobStatus(
    job: { job_status_type_id: number },
    statusList: {
      id: number
      name: string
    }[],
  ) {
    const status = _.find(statusList, t => t.id === job.job_status_type_id)
    return {
      background: this.$getBgColorJobStatus(job.job_status_type_id),
      text: status?.name || 'N.A',
    }
  }

  public $getOrderServiceTypeForJob(
    job: { tasks: { stop: { order: { order_service_type_id: number } } }[] },
    typeList: {
      id: number
      name: string
    }[],
  ) {
    const isSelfManagedOrder = _.find(
      job.tasks,
      t =>
        t.stop.order.order_service_type_id === ORDER_SERVICE_TYPE.SELF_MANAGED,
    )
    let type = _.find(typeList, t => t.id === ORDER_SERVICE_TYPE.STANDARD)

    if (isSelfManagedOrder) {
      type = _.find(typeList, t => t.id === ORDER_SERVICE_TYPE.SELF_MANAGED)
    }

    return {
      background: this.$getBgColorOrderServiceType(type?.id),
      text: type?.name || 'N.A',
    }
  }

  public $getColorList() {
    return [
      {
        hex: 'AB47BC',
        color: 'purple lighten-1',
      },
      {
        hex: '3949AB',
        color: 'indigo darken-1',
      },
      {
        hex: '42A5F5',
        color: 'blue lighten-1',
      },
      {
        hex: '26C6DA',
        color: 'cyan lighten-1',
      },
      {
        hex: '26A69A',
        color: 'teal lighten-1',
      },
      {
        hex: '66BB6A',
        color: 'green lighten-1',
      },
      {
        hex: 'C5E1A5',
        color: 'light-green accent-3',
      },
      {
        hex: 'AFB42B',
        color: 'lime darken-2',
      },
      {
        hex: 'FF6F00',
        color: 'amber darken-4',
      },
      {
        hex: '6D4C41',
        color: 'brown darken-1',
      },
      {
        hex: '546E7A',
        color: 'blue-grey darken-1',
      },
    ]
  }

  public $processUsualServerErrorResponse(
    alerts: {
      type: string
      message: string
    }[],
    error: any,
  ) {
    if (UtilityService.isAxiosError<V1RidaApiErrorResponse>(error)) {
      const e: AxiosError<V1RidaApiErrorResponse> = error

      if (e.response && e.response.data) {
        let message = e.response.data.message

        if (!_.isEmpty(e.response.data.data?.errors)) {
          message +=
            '<br />' +
            _.map(e.response.data.data?.errors, e => {
              return `<dl>
              <dt>${e.field}</dt>
              <dd><li>${e.message}</li></dd>
            </dl>`
            }).join('')
        }
        alerts.push({
          type: 'error',
          message,
        })
        return
      }
    }
    alerts.push({
      type: 'error',
      message: 'Something went wrong, please try again',
    })
    throw error
  }

  public $getConfigValueText(v?: { type: string; value: string }) {
    return v?.type === VALUE_TYPE.FIXED
      ? `${this.$getPrice(v.value)}`
      : `${v?.value || 'N.A'}%`
  }

  public $getValueTypeOptions() {
    return [
      {
        value: VALUE_TYPE.PERCENTAGE,
        text: '%',
      },
      {
        value: VALUE_TYPE.FIXED,
        text: '$',
      },
    ]
  }

  public $getItemCategoryType(v?: { item_category_type_id: number | null }) {
    switch (v?.item_category_type_id) {
      case ITEM_CATEGORY_TYPE.FOOD:
        return {
          icon: 'mdi-bread-slice-outline',
          color: this.$getColorList()[0].color,
          text: 'Food',
        }
      case ITEM_CATEGORY_TYPE.MEDICINE:
        return {
          icon: 'mdi-medication',
          color: this.$getColorList()[3].color,
          text: 'Medicine',
        }
      case ITEM_CATEGORY_TYPE.WINE:
        return {
          icon: 'mdi-bottle-wine-outline',
          color: this.$getColorList()[7].color,
          text: 'Wine',
        }
      default:
        return null
    }
  }

  public $getDriverEquipment(v?: { driver_equipment_type_id: number | null }) {
    switch (v?.driver_equipment_type_id) {
      case DRIVER_EQUIPMENT_TYPE.THERMAL_BAG:
        return {
          icon: 'mdi-medical-bag',
          color: 'primary',
          text: 'Thermal bag required',
        }
      case DRIVER_EQUIPMENT_TYPE.TROLLEY:
        return {
          icon: 'mdi-cart-outline',
          color: 'green darken-1',
          text: 'Trolley required',
        }
      default:
        return null
    }
  }

  public $displayList(v: string[]) {
    return v.join(', ')
  }

  public $getPlatformVehicleType(v: number) {
    switch (v) {
      case VEHICLE_TYPE.BIKE:
        return 'BIKE'
      case VEHICLE_TYPE.CAR:
        return 'CAR'
      case VEHICLE_TYPE.VAN:
        return 'VAN'
      default:
        return 'BIKE'
    }
  }
}
